import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { Heading2Mixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupNameStep({
  homeGroupName,
  setHomeGroupName,
  isError,
}: {
  homeGroupName: string
  setHomeGroupName: (name: string) => void
  isError: boolean
}) {
  const { t, langKeys } = useTranslate()
  return (
    <div>
      <Title>{t(langKeys.organizations_home_groups_create_title)}</Title>
      <TextField
        fullWidth
        label={t(langKeys.name)}
        value={homeGroupName}
        onChange={(e) => setHomeGroupName(e.target.value)}
        required
      />
      {isError && (
        <AlertBox type="error">
          {t(langKeys.failed_something_went_wrong)}
        </AlertBox>
      )}
    </div>
  )
}

const Title = styled.div`
  ${Heading2Mixin};
  margin-bottom: ${spacing.XL};
`

const AlertBox = styled(MBanner)`
  margin-top: ${spacing.M};
`
