import { useState } from "react"

import { HomeGroupNameStep } from "src/components/HomeGroups/HomeGroupsWizard/HomeGroupNameStep"
import { usePostHomeGroup } from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

export interface IHomeOption {
  id: string
  name: string
}

export function HomeGroupsWizard() {
  const { t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()
  const [currentStep, setCurrentStep] = useState(0)
  const [homeGroupName, setHomeGroupName] = useState("")
  const { org } = useOrganization()
  const postHomeGroup = usePostHomeGroup()

  async function createHomeGroup() {
    postHomeGroup.mutate(
      { orgId: org.id, body: { name: homeGroupName } },
      {
        onSuccess(hg) {
          navigate(Routes.HomeGroupDetails.path(hg.id))
        },
      }
    )
  }

  const stepper: IWizardStep[] = [
    {
      component: (
        <HomeGroupNameStep
          homeGroupName={homeGroupName}
          setHomeGroupName={setHomeGroupName}
          isError={postHomeGroup.isError}
        />
      ),
      nextButtonProps: {
        disabled: !homeGroupName,
        loading: postHomeGroup.isLoading,
      },
      backButtonLabel: t(langKeys.cancel),
      nextButtonLabel: t(
        langKeys.organizations_home_group_create_stepper_button_label
      ),
      onBack: () => goBack({ defaultPath: Routes.HomeGroups.location() }),
      onNext: () => createHomeGroup(),
    },
  ]

  return (
    <FullscreenWizard
      title={t(langKeys.organizations_home_group_add_stepper_title)}
      steps={stepper}
      currentStep={currentStep}
      onClose={() => navigate(Routes.HomeGroups.location({}))}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
